import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useCallback, useRef, useState } from 'react'
import { useGetSlidesSetup } from '~/modules/Carousel/hooks/useGetSlidesSetup'
import { ConfigurableProduct } from '@magentoTypes'
import { TeaserItem as TeaserItemType } from '@contentfulTypes'
import { Headline } from '~/elements/Headline/Headline'
import { Carousel, CarouselControls } from '~/modules/Carousel/components/Carousel'
import { SlideNav } from '~/elements/SlideNav/SlideNav'
import { ProductCard } from '~/modules/PLP/components/ProductCard'

const LazyInViewProductRatingLoader = dynamic(
  () => import('../ClientReviews/components/InViewProductRatingLoader'),
  { ssr: false },
)

type SliderProps = {
  products: ConfigurableProduct[]
  headlineText?: string
  h1Headline?: boolean
  lastItemTeaser?: Omit<TeaserItemType, 'contentfulMetadata'> | null | undefined
  firstItemTeaser?: Omit<TeaserItemType, 'contentfulMetadata'> | null | undefined
  cioItemType?: string
}

export const ProductsSlider = ({
  products,
  headlineText,
  h1Headline,
  lastItemTeaser,
  firstItemTeaser,
  cioItemType,
}: SliderProps) => {
  const carouselControlsRef = useRef<CarouselControls | null>(null)
  const { slidesPerView } = useGetSlidesSetup()
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)

  const handlePrevClick = useCallback(() => {
    carouselControlsRef.current?.slideToPrev()
  }, [])

  const handleNextClick = useCallback(() => {
    carouselControlsRef.current?.slideToNext()
  }, [])

  const handleNavigationStateChange = useCallback((atStart: boolean, atEnd: boolean) => {
    setIsAtStart(atStart)
    setIsAtEnd(atEnd)
  }, [])

  if (!products || !products.length) return null

  return (
    <section className="breakout-content-width relative box-border max-w-full overflow-hidden">
      <LazyInViewProductRatingLoader />
      <div
        className={clsx(
          'flex px-4 pt-8 pb-6 lg:px-20 lg:pt-16 lg:pb-8',
          headlineText ? 'justify-between' : 'justify-end',
        )}
      >
        {headlineText ? (
          h1Headline ? (
            <Headline type="h1" styleAs="slider">
              {headlineText}
            </Headline>
          ) : (
            <Headline type="h2" styleAs="slider">
              {headlineText}
            </Headline>
          )
        ) : null}
        {slidesPerView < products.length && (
          <SlideNav
            clickNext={handleNextClick}
            clickPrev={handlePrevClick}
            disableNext={isAtEnd}
            disablePrev={isAtStart}
            className="hidden lg:flex"
          />
        )}
      </div>
      <Carousel
        items={products}
        renderSlide={(product, index, controls) => {
          if (index === 0) {
            carouselControlsRef.current = controls
          }

          return (
            <ProductCard
              cioItemType={cioItemType}
              product={product}
              productPrice={product}
              hasBorderRight
              hasBorderTop
              isSlider
              withCioAttributes
            />
          )
        }}
        pagination
        scrollBy={1}
        onNavigationStateChange={handleNavigationStateChange}
      />
    </section>
  )
}
