import React, { ReactElement } from 'react'

interface CarouselProgressBarProps {
  className?: string
  currentSlide: number
  disableNavigation?: boolean
  totalSlides: number
  slidesPerView: number
  onSlideChange?: (slideIndex: number) => void
  renderCustomProgressBar?: (props: {
    progressPercentage: number
    handleClick: (event: React.MouseEvent<HTMLDivElement>) => void
  }) => ReactElement
}

export const CarouselProgressBar: React.FC<CarouselProgressBarProps> = ({
  className = '',
  currentSlide,
  disableNavigation = false,
  onSlideChange,
  totalSlides,
  slidesPerView,
  renderCustomProgressBar,
}): ReactElement => {
  const maxScrollPosition = Math.max(0, totalSlides - slidesPerView)
  const currentScrollPosition = currentSlide - 1
  const progressPercentage =
    maxScrollPosition > 0 ? Math.min((currentScrollPosition / maxScrollPosition) * 100, 100) : 100

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disableNavigation || !onSlideChange || maxScrollPosition === 0) return

    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const clickPercentage = x / rect.width

    const newScrollPosition = Math.round(clickPercentage * maxScrollPosition)
    const newSlideIndex = Math.min(newScrollPosition + 1, totalSlides - slidesPerView + 1)

    onSlideChange(newSlideIndex)
  }

  if (renderCustomProgressBar) {
    return renderCustomProgressBar({ progressPercentage, handleClick })
  }

  return (
    <div
      className={`mt-[50px] h-0.5 w-full rounded-full bg-sg-grey ${className} ${
        !disableNavigation && onSlideChange && maxScrollPosition > 0 ? 'cursor-pointer' : ''
      }`}
      role="progressbar"
      aria-valuenow={progressPercentage}
      aria-valuemin={0}
      aria-valuemax={100}
      onClick={handleClick}
    >
      <div
        className="h-full rounded-full bg-primary-black transition-all duration-300 ease-in-out"
        style={{ width: `${progressPercentage}%` }}
      />
    </div>
  )
}
