import clsx from 'clsx'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { CarouselNavigation } from './CarouselNavigation'
import { CarouselPagination } from './CarouselPagination'
import { CarouselProgressBar } from './CarouselProgressBar'
import { useGetSlidesSetup, CarouselColumnsConfig } from '../hooks/useGetSlidesSetup'

interface CarouselProps<T> {
  centerIssuficientSlides?: boolean
  gridConfig?: Partial<CarouselColumnsConfig> | number
  items: (T | null | undefined)[]
  isFullWidth?: boolean
  gap?: number
  navigation?: boolean
  pagination?: boolean
  progressbar?: boolean
  scrollBy?: number | 'group'
  renderBullet?: (props: { bulletIndex: number }) => React.ReactNode
  renderCustomProgressBar?: (props: {
    progressPercentage: number
    handleClick: (event: React.MouseEvent<HTMLDivElement>) => void
  }) => React.ReactElement
  renderSlide: (item: T, index: number, carouselControls: CarouselControls) => React.ReactElement
  onNavigationStateChange?: (atStart: boolean, atEnd: boolean) => void
}

export interface CarouselControls {
  slideToPrev: () => void
  slideToNext: () => void
}

export const Carousel = <T,>({
  centerIssuficientSlides = false,
  gridConfig,
  items,
  isFullWidth = true,
  gap = 0,
  navigation = false,
  pagination = false,
  progressbar = false,
  scrollBy = 'group',
  renderBullet,
  renderCustomProgressBar,
  renderSlide,
  onNavigationStateChange,
}: CarouselProps<T>) => {
  const { slidesPerView, highestPossibleSpv, cssConfig, scrollBehaviorPreference } =
    useGetSlidesSetup(gridConfig as CarouselColumnsConfig)
  const scrollerRef = useRef<HTMLDivElement | null>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const slidesRefs = useRef<(HTMLDivElement | null)[]>([])
  const [currentIndex, setCurrentIndex] = useState(1)
  const [currentBulletIndex, setCurrentBulletIndex] = useState(1)
  const [isNextSlidePossible, setIsNextSlidePossible] = useState(true)
  const [slidesRenderedAmount, setSlidesRenderedAmount] = useState(highestPossibleSpv)

  const slides = items.filter((item): item is T => item != null)

  const setRefs = (node: HTMLDivElement | null, index: number) => {
    slidesRefs.current[index] = node
  }

  const carouselConfig = {
    ...cssConfig,
    '--carousel-gap': `${gap}px`,
  } as React.CSSProperties

  const slideGroups = useMemo(() => {
    if (!scrollerRef.current || !slides.length) {
      return []
    }

    const numberOfGroups = Math.ceil(slides.length / slidesPerView)

    return Array.from(new Array(numberOfGroups), (_, i) => {
      const groupStartIndex = i * slidesPerView
      const groupEndIndex = groupStartIndex + slidesPerView

      return slides.slice(groupStartIndex, groupEndIndex)
    })
  }, [slidesPerView, slides])

  const scrollToSlide = useCallback(
    (newIndex: number) => {
      if (scrollerRef.current) {
        const target = scrollerRef.current.children[Math.floor(newIndex) - 1] as HTMLElement

        if (target) {
          scrollerRef.current.scrollTo({
            left: target.offsetLeft,
            behavior: scrollBehaviorPreference,
          })
        }
      }
    },
    [scrollBehaviorPreference],
  )

  const scrollBySlidesAmount = useCallback(
    (direction: 'prev' | 'next') => {
      if (scrollerRef.current && slidesRefs.current.length && scrollBy !== 'group') {
        const firstSlide = slidesRefs.current[0]

        if (!firstSlide) {
          return
        }

        const target = firstSlide.getBoundingClientRect()
        const gap = getComputedStyle(scrollerRef.current)?.getPropertyValue('--carousel-gap') || '0'
        const scrollValue = (target.width + parseInt(gap)) * scrollBy

        scrollerRef.current.scrollBy({
          left: direction === 'prev' ? -scrollValue : scrollValue,
          behavior: scrollBehaviorPreference,
        })
      }
    },
    [scrollBy, scrollBehaviorPreference],
  )

  const scrollToGroup = useCallback(
    (groupIndex: number) => {
      const slideIndex = (groupIndex - 1) * slidesPerView + 1

      scrollToSlide(slideIndex)
    },
    [slidesPerView, scrollToSlide],
  )

  const slideToPrev = useCallback(() => {
    if (scrollBy === 'group') {
      scrollToSlide(currentIndex - slidesPerView > 0 ? currentIndex - slidesPerView : 1)
    } else {
      scrollBySlidesAmount('prev')
    }
  }, [currentIndex, scrollBy, slidesPerView, scrollToSlide, scrollBySlidesAmount])

  const slideToNext = useCallback(() => {
    if (scrollBy === 'group') {
      scrollToSlide(
        currentIndex + slidesPerView < slides.length ? currentIndex + slidesPerView : slides.length,
      )
    } else {
      scrollBySlidesAmount('next')
    }
  }, [currentIndex, scrollBy, slides.length, slidesPerView, scrollToSlide, scrollBySlidesAmount])

  useEffect(() => {
    if (scrollerRef.current && (navigation || pagination)) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const targetIndex = slidesRefs.current.indexOf(entry.target as HTMLDivElement)

              setCurrentIndex(targetIndex + 1)

              if (pagination) {
                setCurrentBulletIndex(Math.floor(targetIndex / slidesPerView) + 1)
              }

              if (targetIndex + slidesPerView > slidesRenderedAmount) {
                setSlidesRenderedAmount(targetIndex + slidesPerView)
              }
            }
          })
        },
        {
          root: scrollerRef.current.parentNode as HTMLElement,
          threshold: 0.75,
        },
      )

      slidesRefs.current.forEach((ref, index) => {
        if (ref && index % slidesPerView === 0) {
          observerRef.current?.observe(ref)
        }
      })
    }

    return () => {
      observerRef.current?.disconnect()
    }
  }, [navigation, pagination, slidesPerView, slidesRenderedAmount])

  useEffect(() => {
    if (slides.length <= slidesPerView) {
      return
    }

    const lastSlideObserver = new IntersectionObserver(
      ([entry]) => {
        setIsNextSlidePossible(!entry.isIntersecting)
      },
      {
        root: scrollerRef.current,
        threshold: 0.75,
      },
    )

    const lastSlide = slidesRefs.current[slidesRefs.current.length - 1]

    if (lastSlide) {
      lastSlideObserver.observe(lastSlide)
    }

    return () => {
      lastSlideObserver.disconnect()
    }
  }, [navigation, slides.length, slidesPerView])

  const carouselControls: CarouselControls = {
    slideToPrev,
    slideToNext,
  }

  const handleProgressBarClick = (slideIndex: number) => {
    scrollToSlide(slideIndex)
  }

  useEffect(() => {
    if (onNavigationStateChange) {
      onNavigationStateChange(currentIndex === 1, !isNextSlidePossible)
    }
  }, [currentIndex, isNextSlidePossible, onNavigationStateChange])

  return (
    <div
      data-role="carousel"
      className={`grid grid-cols-1 ${
        pagination && slideGroups.length > 1 ? 'grid-rows-[1fr_4rem]' : 'grid-rows-1'
      }`}
      style={carouselConfig}
    >
      <div data-role="carousel-wrapper" className="relative row-start-1">
        {navigation && slideGroups.length > 1 && (
          <CarouselNavigation
            currentIndex={currentIndex}
            isNextSlidePossible={isNextSlidePossible}
            isFullWidth={isFullWidth}
            onSlideToPrev={slideToPrev}
            onSlideToNext={slideToNext}
          />
        )}
        <div
          ref={scrollerRef}
          data-role="carousel-slides"
          className={clsx(
            'relative z-[1] grid snap-x snap-mandatory auto-cols-[calc(100%_/_var(--carousel-slidesPerView)_-_((var(--carousel-slidesPerView)_-_1)_*_var(--carousel-gap))_/_var(--carousel-slidesPerView))] grid-flow-col items-center gap-[var(--carousel-gap)] overflow-x-auto overscroll-x-contain',
            {
              'w-full': isFullWidth,
              '[justify-content:safe_center]':
                centerIssuficientSlides && slidesPerView > slides.length,
            },
          )}
          role="group"
          aria-live="polite"
        >
          {slides.map((item, index) => (
            <div
              key={`slider-${index}`}
              ref={(node) => setRefs(node, index)}
              className="snap-start place-items-start self-stretch"
              aria-label={`${index + 1} / ${slides.length}`}
              aria-roledescription="slide"
            >
              {renderSlide(item, index, carouselControls)}
            </div>
          ))}
        </div>
      </div>
      {pagination && slideGroups.length > 1 && (
        <CarouselPagination
          groupsCount={slideGroups.length}
          currentIndex={currentBulletIndex}
          onSlideTo={scrollToGroup}
          renderBullet={renderBullet}
        />
      )}
      {progressbar && slideGroups.length > 1 && (
        <CarouselProgressBar
          currentSlide={currentIndex}
          disableNavigation={false}
          renderCustomProgressBar={renderCustomProgressBar}
          slidesPerView={slidesPerView}
          totalSlides={slides.length}
          onSlideChange={handleProgressBarClick}
        />
      )}
    </div>
  )
}
