interface CarouselPaginationProps {
  groupsCount: number
  currentIndex: number
  onSlideTo: (index: number) => void
  renderBullet?: (props: { bulletIndex: number }) => React.ReactNode
}

export const CarouselPagination: React.FC<CarouselPaginationProps> = ({
  groupsCount,
  currentIndex,
  onSlideTo,
  renderBullet,
}) => {
  return (
    <div data-role="carousel-pagination" className="flex items-center">
      <nav
        data-role="carousel-pagination-content"
        className="relative z-[1] flex h-auto w-full items-center justify-center gap-[15px] bg-transparent transition-colors"
        role="tablist"
      >
        {Array.from({ length: groupsCount }, (_, i) => i + 1).map((n) => (
          <div
            key={n}
            data-role="carousel-pagination-item"
            className="flex"
            aria-label={`Slide ${n}`}
            aria-current={n === currentIndex}
            aria-setsize={groupsCount}
            aria-posinset={n}
            role="tab"
            tabIndex={-1}
            onClick={() => onSlideTo(n)}
          >
            {renderBullet ? (
              renderBullet({ bulletIndex: n })
            ) : (
              <span
                data-role="carousel-pagination-bullet"
                className={`h-3 w-3 rounded-full ${
                  n === currentIndex
                    ? 'cursor-auto bg-primary-black '
                    : 'cursor-pointer bg-sg-grey hover:bg-grey-medium'
                }`}
              />
            )}
          </div>
        ))}
      </nav>
    </div>
  )
}
