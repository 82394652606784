import { useState, useEffect, useMemo } from 'react'
import themeConfig from 'theme'

const { screens } = themeConfig.theme

interface BreakpointInfo {
  key: keyof typeof screens
  size: string
  isActive: boolean
}

const parsePx = (value: string): number => {
  const parsed = parseInt(value, 10)

  if (isNaN(parsed)) {
    console.warn(`Invalid breakpoint value: ${value}`)

    return 0
  }

  return parsed
}

const initialBreakpoints: BreakpointInfo[] = Object.keys(screens)
  .map((key) => ({
    key: key as keyof typeof screens,
    size: screens[key as keyof typeof screens],
    isActive: false,
  }))
  .sort((a, b) => parsePx(a.size) - parsePx(b.size))

const createMediaQueries = (breakpoints: BreakpointInfo[]): string[] => {
  return breakpoints.map((bp, index) => {
    const currentSize = parsePx(bp.size)

    if (index === 0) {
      const nextSize = parsePx(breakpoints[index + 1].size)

      return `screen and (min-width: ${currentSize}px) and (max-width: ${nextSize - 0.1}px)`
    } else if (index === breakpoints.length - 1) {
      return `(min-width: ${currentSize}px)`
    } else {
      const nextSize = parsePx(breakpoints[index + 1].size)

      return `(min-width: ${currentSize}px) and (max-width: ${nextSize - 0.02}px)`
    }
  })
}

export const useBreakpoints = () => {
  const [isTouch, setIsTouch] = useState(false)
  const [activeBreakpoints, setActiveBreakpoints] = useState<BreakpointInfo[]>(initialBreakpoints)

  const currentBreakpoint = useMemo(
    () => activeBreakpoints.find((bp) => bp.isActive),
    [activeBreakpoints],
  )

  const mediaQueries = useMemo(() => createMediaQueries(initialBreakpoints), [])

  useEffect(() => {
    if (typeof window === 'undefined') return

    const mqlList = mediaQueries.map((query) => window.matchMedia(query))

    const handleBreakpointChange = () => {
      const newActiveBreakpoints = initialBreakpoints.map((bp, index) => ({
        ...bp,
        isActive: mqlList[index].matches,
      }))
      setActiveBreakpoints(newActiveBreakpoints)
    }

    mqlList.forEach((mql) => {
      mql.addEventListener('change', handleBreakpointChange)
    })

    handleBreakpointChange()

    const touchMQ = window.matchMedia('(hover: none) and (pointer: coarse)')
    const handleTouchChange = () => setIsTouch(touchMQ.matches)

    handleTouchChange()
    touchMQ.addEventListener('change', handleTouchChange)

    return () => {
      mqlList.forEach((mql) => {
        mql.removeEventListener('change', handleBreakpointChange)
      })
      touchMQ.removeEventListener('change', handleTouchChange)
    }
  }, [mediaQueries])

  return {
    isTouch,
    breakpoints: activeBreakpoints,
    currentBreakpoint,
  }
}
