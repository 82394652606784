interface CarouselNavigationProps {
  currentIndex: number
  isNextSlidePossible: boolean
  isFullWidth: boolean
  onSlideToPrev: () => void
  onSlideToNext: () => void
}

export const CarouselNavigation: React.FC<CarouselNavigationProps> = ({
  currentIndex,
  isNextSlidePossible,
  onSlideToPrev,
  onSlideToNext,
}) => {
  return (
    <div data-role="carousel-navigation">
      <button
        data-role="carousel-navigation-prev-button"
        className={`bg-white absolute left-0 top-[var(--carousel-arrow-position)] z-[2] flex h-[50px] w-[50px] -translate-y-1/2 items-center justify-center overflow-hidden lg:h-20 lg:w-20 ${
          currentIndex !== 1
            ? 'lg:hover:bg-gray-200 cursor-pointer lg:transition-colors'
            : 'text-zinc-300'
        }`}
        disabled={currentIndex === 1}
        aria-label="Previous slide"
        onClick={onSlideToPrev}
      >
        <span className="w-6">&larr</span>
      </button>
      <button
        data-role="carousel-navigation-next-button"
        className={`bg-white absolute right-0 top-[var(--carousel-arrow-position)] z-[2] flex h-[50px] w-[50px] -translate-y-1/2 items-center justify-center overflow-hidden lg:h-20 lg:w-20 ${
          isNextSlidePossible
            ? 'lg:hover:bg-gray-200 cursor-pointer lg:transition-colors'
            : 'text-zinc-300'
        }`}
        disabled={!isNextSlidePossible}
        aria-label="Next slide"
        onClick={onSlideToNext}
      >
        <span className="w-6">&rarr</span>
      </button>
    </div>
  )
}
